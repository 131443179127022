.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.selectOptionDropDown{
  display: none;
}


.custom-select-search-container.custom-select-search-has-focus .selectOptionDropDown{
  display: block;
}

/* .Mui-focused{
  background-color: transparent !important;
} */

/* 
"@emotion/react": "^11.11.1",
    "@emotion/styled": "^11.11.0",
    "@heroicons/react": "^2.0.12",
    "@mui/icons-material": "^5.10.6",
    "@mui/lab": "^5.0.0-alpha.137",
    "@mui/material": "^5.14.1", */