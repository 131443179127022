@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color1: #012E5D;
  --secondary: #FF9635;
  --color2: #37474f;
  --color3: #f44336;
}

@layer components {
  .container_xxl{
    @apply mx-0
  }
  .row{
    @apply flex flex-wrap
  }
  .titleHeading{
    @apply text-4xl font-semibold py-5 text-gray-700 text-center
  }
  .heading{
    @apply text-4xl font-semibold py-5 text-gray-700
  }
  .headingBorder__b{
    @apply border-b border-gray-600
  }
  .label {
    @apply block text-gray-500 font-bold  mb-1 md:mb-0 pr-4
  }

  .input {
    @apply w-full border border-gray-400 focus:outline-none focus-within:shadow px-3 py-2 rounded placeholder:italic placeholder:text-sm
  }

  /* .custom-select-search-value {
    @apply w-full border border-gray-400 focus:outline-none focus-within:shadow px-3 py-2 rounded placeholder:italic placeholder:text-sm
  } */

  .text_area {
    @apply border min-h-[6rem] rounded px-3 py-2 focus:outline-none focus-within:shadow placeholder:italic placeholder:text-sm w-full
  }

  .mandatory {
    @apply text-2xl text-red-800
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eceff1;
}


.primaryBtn-outlined{
  border: 1px solid var(--color3) !important;
  color: var(--color3) !important;
}

.hide_show_switch .MuiSwitch-switchBase.Mui-checked{
  color: var(--color3) !important;
}
.hide_show_switch .Mui-checked+.MuiSwitch-track{
  background-color: var(--color3) !important;
}

.file-uploader{
  width: 100%;
  max-width: 100% !important;
  height: 10rem !important;
  padding: 0 5rem !important;
  border: 1px dashed var(--color3)
}

.input-container{
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}


.pageNumber{
  border: 1px solid var(--color3);
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedPageNumber{
  background-color: var(--color3);
  color: #fff;
}
.lastPage{
  border: none;
  cursor: not-allowed;
  border-radius: 100%;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.shadow-right{
  box-shadow: 2px 0px 16px 0px #00000042;
}

.MuiTreeItem-label{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.MuiTreeItem-content{
  border-radius: 0.5rem;
}

.react-datepicker-popper{
  z-index: 40 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__calendar-icon {
  margin-top: 3px;
}

.MuiInputBase-root {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}


/* SelectSearch.css */
.custom-select-search-container {
  position: relative;
}

.custom-select-search-value {
  width: 100%;
  background-color: white;
  overflow: hidden;
}
.custom-select-search-input {
  width: 100%;
  background-color: white;
  overflow: hidden;
  border: 1px solid rgb(156 163 175);
  padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.25rem;
}

.custom-select-search-container.custom-select-search-has-focus .custom-select-search-select{
  display: block;
}

.custom-select-search-select{
  position: absolute;
  background: white;
  width: 100%;
  top: 120%;
  overflow: auto;
  max-height: 360px;
  z-index: 10;
  display: none;
}

.custom-select-search-options{
  margin-bottom: 1rem;
}

.custom-select-search-option{
  display: block;
  height: 42px;
  width: 100%;
  padding: 0 16px;
  border: none;
  outline: none;
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.01rem;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
}

.custom-select-search-is-selected{
  background-color: #133457af;
}

.custom-select-search__options {
  /* Custom styles for the options list */
}

.custom-select-search__option {
  /* Custom styles for individual options */
}

.custom-select-search__option--is-selected {
  /* Custom styles for selected option */
}